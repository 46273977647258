import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/pricing.css';
import Shape1 from '../png/shape-top-right.png';

const Pricing = () => {
  const navigate = useNavigate();
  const [billingCycle, setBillingCycle] = useState('annual');

  const prices = {
    annual: {
      1: { price: '$668/year' },
      2: { price: '$609/year' },
      3: { price: '$548/year' }
    },
    monthly: {
      1: { price: '$56/month' },
      2: { price: '$51/month' },
      3: { price: '$46/month' }
    }
  };

  const handleChoosePlan = (year) => {
    navigate('/payment', {
      state: {
        year,
        subscriptionType: billingCycle
      }
    });
  };

  return (
    <div className='pricing-wrapper'>
      <div className='pricing-header'>
        <img src={Shape1} alt="shape" className='shape' />
        <h2>Our Pricing Plans</h2>
        <p>Choose your preferred billing cycle</p>
        <div className="billing-toggle">
          <button
            className={`toggle-btn ${billingCycle === 'annual' ? 'active' : ''}`}
            onClick={() => setBillingCycle('annual')}
          >
            Annual Billing
          </button>
          <button
            className={`toggle-btn ${billingCycle === 'monthly' ? 'active' : ''}`}
            onClick={() => setBillingCycle('monthly')}
          >
            Monthly Billing
          </button>
        </div>
      </div>
      <div className='pricing-body'>
        <div className='pricing-container'>
          <div className='item pricing'>
            <h2 className="title">1 year contract</h2>
            <div className="item_price">
              {prices[billingCycle][1].price}
            </div>
            <ul className="featureList">
              <li className="check_list">Water Level Monitoring App</li>
              <li className="check_list">Automatic Alerts</li>
              <li className="check_list">Basic Support</li>
              <li className="cross_list"></li>
              <li className="cross_list"></li>
            </ul>
            <button type="button" className="buttonstyle" onClick={() => handleChoosePlan('1')}>
              <span className="btn-text">Choose Plan</span>
            </button>
          </div>
          <div className='item pricing'>
            <h2 className="title">2 year contract</h2>
            <div className="item_price">
              {prices[billingCycle][2].price}
            </div>
            <ul className="featureList">
              <li className="check_list">Water Level Monitoring App</li>
              <li className="check_list">Automatic Alerts</li>
              <li className="check_list">Priority Support</li>
              <li className="check_list">Annual Maintenance</li>
              <li className="cross_list"></li>
            </ul>
            <button type="button" className="buttonstyle" onClick={() => handleChoosePlan('2')}>
              <span className="btn-text">Choose Plan</span>
            </button>
          </div>
          <div className='item pricing' style={{ marginBottom: "35px" }}>
            <div className="recommended">Popular Package</div>
            <h2 className="title">3 year contract</h2>
            <div className="item_price">
              {prices[billingCycle][3].price}
            </div>
            <ul className="featureList">
              <li className="check_list">Water Level Monitoring App</li>
              <li className="check_list">Automatic Alerts</li>
              <li className="check_list">Free Water Test</li>
              <li className="check_list">Annual Maintenance</li>
              <li className="check_list">Free Installation</li>
            </ul>
            <button type="button" className="buttonstyle" onClick={() => handleChoosePlan('3')}>
              <span className="btn-text">Choose Plan</span>
            </button>
          </div>
        </div>
        <div className='pricing-footer'>
          <h2>Own a business? Let's talk about our business pricing.</h2>
        </div>
      </div>
    </div>
  );
};

export default Pricing;