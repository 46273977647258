import React from 'react';
import logo from '../png/logo_160.png';
import appImg from '../png/app-store.png';
import playStore from '../png/playstore.png';
import './styles/footer.css';


const date = new Date();
const year = date.getFullYear();

const Footer = () => (
    <div className='footer-section'>
    <div className='footer-main'>
        <div className='logo-section'>
        <div className="logo">
            <img className="img-header" src={logo} alt="logo"/>
            </div>
            <h2>Download The App</h2>
            <div className='footer-img'>
            <a href="https://apps.apple.com/us/app/h2zero/id1602519505">
            <img className="img-header" src={appImg} alt="logo"/>
            </a>
            </div>
            <div className='footer-img'>
            <a href="https://play.google.com/store/apps/details?id=com.h2zero&hl=en_US&gl=GB">
            <img className="img-header" src={playStore} alt="logo"/>
            </a>
            </div>
            </div>
        <div className='contact-info'>
        <h3> Contact info</h3>
        <p className='address'>Paget, Bermuda</p>
        <div className='contact'>
        <p>+1-441-704-1014<br />
            WhatsApp
        </p>
        </div>
        <p className='email'>info@h2zero.bm</p>
        </div>

    </div>
    <div className="nav-bar">
        <div className='footer-bar'><p>All rights reserved. Copyright H2Zero Ltd {year}</p></div>
            <div className='footer-bottom-menu'>
            <ul className="menu">

                <a href="https://s3.amazonaws.com/app.h2zero.bm/Terms_and_Conditions_H2Zero_TankWaterMonitoringCompany+.pdf">
                <li>Terms & Conditions</li>
                </a>
            </ul>
            </div>
            <div className='footer-bottom-social-icons'>
            <ul className="icon ">
                <p>Social:</p>
                <li><i className='fa fa-facebook social-icons'></i></li>
                <li><i className='fa fa-instagram social-icons'></i></li>
                <li><i className='fa fa-youtube-play social-icons'></i></li>
                <li><i className='fa fa-file-pdf-o social-icons'></i></li>
            </ul>
            </div>
        </div>
</div>
    )



export default Footer ;