import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import './styles/paymentpage.css';

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { year, subscriptionType } = location.state || {};

  const prices = {
    annual: {
      1: { duration: '1 Year', price: '$668 year' },
      2: { duration: '2 Years', price: '$609 per year' },
      3: { duration: '3 Years', price: '$548 per year' }
    },
    monthly: {
      1: { duration: '1 Year', price: '$56 per month' },
      2: { duration: '2 Years', price: '$51 per month' },
      3: { duration: '3 Years', price: '$46 per month' }
    }
  };

  const getPlanDetails = () => {
    return prices[subscriptionType][year] || {};
  };

  const planDetails = getPlanDetails();

  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [focused, setFocused] = useState('');

  const handlePaymentSubmit = (event) => {
    event.preventDefault();
    navigate('/processing-payment', {
      state: {
        paymentData: {
          cardData: {
            number: cardNumber,
            expiry,
            cvc,
            name,
            email,
            address,
            city,
            zip
          },
          planDetails: getPlanDetails()
        },
        subscriptionType,
        year
      }
    });
  };

  if (!year || !subscriptionType) {
    navigate('/');
    return null;
  }

  return (
    <div className="payment-page">
      <div className="payment-container">
        <button className="back-button" onClick={() => navigate(-1)}>
          ← Back to Plans
        </button>
        <div className="plan-summary">
          <h2>Complete Your Purchase</h2>
          <div className="plan-details">
            <p><strong>Duration:</strong> {planDetails.duration}</p>
            <p><strong>Billing Cycle:</strong> {subscriptionType === 'annual' ? 'Annual' : 'Monthly'}</p>
            <p><strong>Price:</strong> {planDetails.price}</p>
          </div>
        </div>
        <div className="payment-form">
          <h3>Payment Details</h3>
          <Cards
            number={cardNumber}
            expiry={expiry}
            cvc={cvc}
            name={name}
            focused={focused}
          />
          <form onSubmit={handlePaymentSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Cardholder Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="address"
                placeholder="Street Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
                required
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <input
                  type="text"
                  name="city"
                  placeholder="Parish"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  onFocus={(e) => setFocused(e.target.name)}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="zip"
                  placeholder="ZIP Code"
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                  onFocus={(e) => setFocused(e.target.name)}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <input
                type="tel"
                name="number"
                placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                maxLength="19"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                onFocus={(e) => setFocused(e.target.name)}
                required
              />
            </div>
            <div className="form-row">
              <div className="form-group expiry">
                <input
                  type="tel"
                  name="expiry"
                  placeholder="MM/YY"
                  pattern="\d\d/\d\d"
                  maxLength="5"
                  value={expiry}
                  onChange={(e) => setExpiry(e.target.value)}
                  onFocus={(e) => setFocused(e.target.name)}
                  required
                />
              </div>
              <div className="form-group cvc">
                <input
                  type="tel"
                  name="cvc"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  maxLength="4"
                  value={cvc}
                  onChange={(e) => setCvc(e.target.value)}
                  onFocus={(e) => setFocused(e.target.name)}
                  required
                />
              </div>
            </div>
            <button type="submit" className="submit-button">
              Pay Now
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
