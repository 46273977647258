import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import './styles/processingPayment.css';

const ProcessingPayment = () => {
  const [status, setStatus] = useState('processing');
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentData } = location.state || {};

  useEffect(() => {
    const processPayment = async () => {
      if (!paymentData) {
        setStatus('error');
        return;
      }

      const { cardData, planDetails } = paymentData;
      
      // Initialize Google Tag Manager
      const tagManagerArgs = {
        gtmId: 'GTM-P3PVRNQ',
        dataLayer: {
          page: 'payment',
          price: planDetails.price,
          event: 'payment'
        }
      };
      TagManager.initialize(tagManagerArgs);

      try {
        // Format the expiry date
        const [month, year] = cardData.expiry.split('/');
        const formattedYear = '20' + year; // Convert YY to YYYY

        // Construct the API URL with query parameters
        const apiUrl = 'https://l6ccsiypgl.execute-api.us-east-1.amazonaws.com/beta';
        
        // Split the name into first and last name, handling the case where last name might not exist
        const nameParts = cardData.name.trim().split(/\s+/);
        const firstName = nameParts[0] || '';
        const lastName = nameParts.slice(1).join(' ') || '';

        const params = new URLSearchParams({
          fName: firstName,
          lName: lastName,
          customerEmail: cardData.email,
          ccNum: cardData.number.replace(/\s/g, ''),
          exDate: `${formattedYear}-${month.padStart(2, '0')}`,
          ccCode: cardData.cvc,
          subscriptionType: '',
          year: '',
          city: cardData.city || '',
          zip: cardData.zip || '',
          address: cardData.address || ''
        });

        // Make the API request
        const response = await axios({
          method: 'post',
          url: apiUrl,
          params: params,
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log('Payment Response:', response);
        setStatus('success');
      } catch (error) {
        console.error('Payment Error:', error);
        setStatus('error');
      }
    };

    processPayment();
  }, [paymentData]);

  const handleButtonClick = () => {
    if (status === 'success') {
      navigate('/');  // Return to home page
    } else {
      navigate(-1);   // Go back to payment page
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'processing':
        return (
          <div className="processing-content">
            <div className="spinner" data-testid="loading-animation"></div>
            <h2>Processing Your Payment</h2>
            <p>Please wait while we process your payment...</p>
          </div>
        );
      case 'success':
        return (
          <div className="success-content">
            <div className="success-icon">✓</div>
            <h2>Payment Successful!</h2>
            <p>Thank you for your purchase. You will receive a confirmation email shortly.</p>
            <button onClick={handleButtonClick} className="action-button">
              Return to Home
            </button>
          </div>
        );
      case 'error':
        return (
          <div className="error-content">
            <div className="error-icon">✕</div>
            <h2>Payment Failed</h2>
            <p>We're sorry, but there was an error processing your payment. Please try again.</p>
            <button onClick={handleButtonClick} className="action-button">
              Try Again
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="processing-payment-container">
      {renderContent()}
    </div>
  );
};

export default ProcessingPayment;
