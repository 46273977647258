import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './credit-card-form.css';
import Footer from './components/footer.js';
import Pricing from './components/pricing.js';
import PaymentPage from './components/PaymentPage';
import ProcessingPayment from './components/ProcessingPayment';

function App() {
  return (
    <Router>
      <div className="app-container">
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Pricing />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/processing-payment" element={<ProcessingPayment />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;